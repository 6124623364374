import React from "react";
import GATSBY_COMPILED_MDX from "C:/Users/ivangsa/workspace/zenwave/ZenWave360.github.io/website/pages/docs/SDK/Exposing-REST-API.mdx";
import {graphql} from 'gatsby';
export const pageQuery = graphql`
  query DocPageQuery($id: String!) {
    mdx(id: { eq: $id }) {
      fields {
        pageType
        title
      }
      tableOfContents
    }
  }
`;
function DocTemplate({children}) {
  return children;
}
DocTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(DocTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
